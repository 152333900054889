import React from "react";
import { projectNameMaxLength } from "../../config";
import { Project } from "../../providers/project";

interface Props {
  onProjectNameChange: (name: string) => void;
  project: Project;
}

const TrackDetails: React.FC<Props> = ({ project, onProjectNameChange }) => (
  <div className="toolbar__track-details">
    {project.isShare ? (
      <>
        <div className="toolbar__shared-track-name">{project.name}</div>
        <div className="toolbar__shared-track-author">
          by {project.makername}
        </div>
      </>
    ) : (
      <>
        <input
          className="toolbar__track-name"
          type="text"
          maxLength={projectNameMaxLength}
          value={project.name}
          onChange={({ target }) => onProjectNameChange(target.value)}
          disabled
        />
      </>
    )}
  </div>
);

export default TrackDetails;
