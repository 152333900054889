import React from "react";

const TrashIcon = () => (
  <svg className="icon" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill="#414141" fillRule="nonzero" cx="15" cy="15" r="15"/>
      <path d="M21.007 8.2c.615 0 1.112.577 1.112 1.29v.174c0 .714-.497 1.293-1.112 1.293H9.113c-.614 0-1.113-.58-1.113-1.293V9.49c0-.712.5-1.29 1.113-1.29h1.832v-.77c0-.652.454-1.18 1.015-1.18h6.2c.56 0 1.015.528 1.015 1.18v.77h1.832zm-.462 3.82c.456 0 .81.418.755.891l-1.077 9.32c-.15 1.308-1.212 2.289-2.47 2.289h-5.37c-1.257 0-2.316-.979-2.47-2.281l-1.1-9.325c-.058-.475.298-.894.754-.894h10.978zm-7.379 10.058a.936.936 0 0 0 .935-1.016l-.465-5.467c-.044-.517-.506-.909-1.012-.854a.936.936 0 0 0-.856 1.013l.465 5.466c.042.49.452.858.933.858zm4.034.008a.938.938 0 0 0 .935-.881l.326-5.474a.938.938 0 0 0-.88-.993c-.526-.047-.961.365-.991.88l-.326 5.474a.938.938 0 0 0 .936.994z" fill="#FAD205"/>
    </g>
  </svg>
);

export default TrashIcon;