const analyticsExists = () => typeof window.analytics !== "undefined";

export const identify = (id: string) => {
  if (analyticsExists()) {
    window.analytics.ready(() => window.analytics.identify(id));
  }
};

const trackEvent = (event: string): void => {
  if (analyticsExists()) {
    window.analytics.ready(() => window.analytics.track(event));
  } else {
    // dev environment
    if (window.debugAnalytics) {
      console.log(`Tracked event "${event}"`);
    }
  }
};

export const trackRecordingStarted = () => trackEvent("Recording Started");
export const trackRecordingFinished = () => trackEvent("Recording Finished");
export const trackRecordingDeleted = () => trackEvent("Recording Deleted");

export const trackMetronomeEnabled = () => trackEvent("Metronome Enabled");
export const trackMetronomeDisabled = () => trackEvent("Metronome Disabled");

export const trackProjectPlayed = () => trackEvent("Project Played");
export const trackProjectPaused = () => trackEvent("Project Paused");
export const trackProjectSaved = () => trackEvent("Project Saved");
export const trackProjectRenamed = () => trackEvent("Project Renamed");
export const trackProjectBpmChanged = () => trackEvent("Project BPM Changed");

export const trackProjectSaveError = () => trackEvent("Project Save Error");
export const trackProjectLoadError = () => trackEvent("Project Load Error");

export const trackSharingDialogOpened = () =>
  trackEvent("Sharing Dialog Opened");
export const trackSharingDialogClosed = () =>
  trackEvent("Sharing Dialog Closed");

export const trackShareLinkCopied = () => trackEvent("Share Link Copied");
