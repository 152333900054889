import React, { useContext, useState } from "react";
import "./styles.css";

import fastIcon from "./icons/fast.png";
import slowIcon from "./icons/slow.png";

import HomeIcon from "./icons/Home";
import MetronomeIcon from "./icons/Metronome";
import PlayIcon from "./icons/Play";
import ShareToggleIcon from "./icons/ShareToggle";
import StopIcon from "./icons/Stop";

import JaggedEdge from "./JaggedEdge";
import Timeline from "./Timeline";
import TrackDetails from "./TrackDetails";

import ShareIcon from "./icons/Share";

import { ProjectContext } from "../../providers/project";
import { AudioContext } from "../../providers/audio";
import {
  trackMetronomeDisabled,
  trackMetronomeEnabled,
  trackProjectPaused,
  trackProjectPlayed,
} from "../../helpers/analytics";

interface Props {
  sharingVisible: boolean;
  onSharingPress: () => void;
  onSharingClose: () => void;
  homeUrl: string;
}

const Toolbar: React.FC<Props> = ({
  sharingVisible,
  onSharingPress,
  onSharingClose,
  homeUrl,
}) => {
  const { project, updateProjectName, masterBpm } = useContext(ProjectContext);
  const {
    playing,
    togglePlaying,
    metronome: metronomePlaying,
    toggleMetronome,
    setBpm,
    recording,
    recordingRequested,
  } = useContext(AudioContext);
  const [showBpm, setShowBpm] = useState(false);
  const [bpmPosition, setBpmPosition] = useState({ top: 0, left: 0 });

  return (
    <>
      <div className="toolbar">
        <a href={homeUrl} className="toolbar__home">
          <span className="visuallyhidden">Click to return home</span>
          <HomeIcon />
        </a>
        <div className="mq-min-800">
          <TrackDetails
            project={project}
            onProjectNameChange={updateProjectName}
          />
        </div>
        <JaggedEdge />
        <div className="toolbar__controls">
          <button
            className="toolbar__play"
            onClick={() => {
              if (!recordingRequested) {
                if (playing) {
                  trackProjectPaused();
                } else {
                  trackProjectPlayed();
                }
                togglePlaying();
              }
            }}
          >
            {playing ? <StopIcon /> : <PlayIcon />}
          </button>
          <Timeline recording={recording} />
          <div className="bpm">
            {showBpm && (
              <span className="bpm__value" style={bpmPosition}>
                {masterBpm} BPM
              </span>
            )}
            <img
              className="bpm__speed-icon bpm__slow-icon"
              src={slowIcon}
              alt=""
            />
            <input
              onChange={({ target }) => {
                const bpm = parseInt(target.value, 10);
                setBpm(bpm);
              }}
              type="range"
              min="60"
              max="180"
              value={masterBpm}
              className="bpm__slider"
              id="myRange"
              onMouseMove={({ pageX, pageY }) =>
                showBpm &&
                setBpmPosition({
                  left: pageX,
                  top: pageY,
                })
              }
              onMouseDown={({ pageX, pageY }) => {
                setShowBpm(true);
                setBpmPosition({ left: pageX, top: pageY });
              }}
              onMouseUp={() => setShowBpm(false)}
            />
            <img
              className="bpm__speed-icon bpm__fast-icon"
              src={fastIcon}
              alt=""
            />
          </div>
          <button
            className={`toolbar__metronome ${
              playing && metronomePlaying ? "toolbar__metronome--active" : ""
            }`}
            onClick={() => {
              if (metronomePlaying) {
                trackMetronomeDisabled();
              } else {
                trackMetronomeEnabled();
              }
              toggleMetronome();
            }}
            style={{
              ["animationDuration" as any]: `${(1000 * 60) / masterBpm}ms`,
            }}
          >
            <MetronomeIcon />
          </button>
        </div>
        {!project.isShare && project._id && (
          <>
            <JaggedEdge invert={true} />
            <button
              onClick={sharingVisible ? onSharingClose : onSharingPress}
              className="toolbar__share"
            >
              <span className="mq-max-800">
                <ShareToggleIcon toggled={sharingVisible} />
                <span className="visuallyhidden">Share</span>
              </span>
              <span className="mq-min-800-flex">
                <ShareIcon />
              </span>
              <span className="toolbar__share-link-text">Share</span>
            </button>
          </>
        )}
      </div>
      <div className="mq-max-800">
        <TrackDetails
          project={project}
          onProjectNameChange={updateProjectName}
        />
      </div>
    </>
  );
};

export default Toolbar;
