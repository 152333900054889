import { projectNameMaxLength } from '../config';

const dictionary = {
  firstAdjectives: [
    'Super',
    'Ultra',
    'Mega',
    'Uber',
    'Great',
    'Totally',
    'Incredible',
    'Amazing',
    'Awesome',
    'Fantastic',
    'Incredible',
    'Outstanding',
    'Interesting',
    'Exciting',
    'Extraordinary',
    'Formidable',
    'Futuristic',
    'Explosive',
    'Colourful',
    'Ridiculous'
  ],
  secondAdjectives: [
    'Smart',
    'Crazy',
    'Cool',
    'Secret',
    'Wicked',
    'Genius',
    'Magic',
    'Special',
    'Fast',
    'Speedy',
    'Lightning',
    'Clever',
    'Wacky',
    'Flashy',
    'Shiny',
    'Wicked',
    'Cunnning',
    'Fun',
    'Silly',
    'Random',
  ],
  nouns: [
    'Song',
    'Music',
    'Sounds',
    'Beats',
    'Banger',
    'Ditty',
    'Melody',
    'Tune',
    'Track',
    'Classic',
    'Anthem'
  ]
};

const generateRandomNumber = (max: number) => {
  return Math.floor(Math.random() * max);
}

const generateRandomName = () => {
  const firstAdjective = dictionary.firstAdjectives[generateRandomNumber(dictionary.firstAdjectives.length)];
  const secondAdjective = dictionary.secondAdjectives[generateRandomNumber(dictionary.secondAdjectives.length)];
  const noun = dictionary.nouns[generateRandomNumber(dictionary.nouns.length)];

  return `${firstAdjective} ${secondAdjective} ${noun}`;
}

const getRandomName = () => {
  let name = generateRandomName();

  while (name.length > projectNameMaxLength) {
    name = generateRandomName();
  }

  return name;
}

export default getRandomName;
