import React from "react";
import cx from "classnames";

import "./styles.scss";
import TrashIcon from "./icons/Trash";

import GetReady from "../get-ready";

import { ProjectContext } from "../../providers/project";
import { AudioService } from "../../services/audio";
import { AudioContextType } from "../../providers/audio";
import {
  trackRecordingFinished,
  trackRecordingStarted,
} from "../../helpers/analytics";

interface State {
  countdown: number;
  countdownIsActive: boolean;
  recordingInProgress: boolean;
  getReady: boolean;
}

interface Props {
  audioService: AudioService;
  index: number;
  audioContext: AudioContextType;
}

class Window extends React.Component<Props, State> {
  static contextType = ProjectContext;
  context!: React.ContextType<typeof ProjectContext>;

  private animationLoop?: number;
  private canvasElement = React.createRef<HTMLCanvasElement>();
  private countdownDefault = 5;
  private fft?: ReturnType<typeof AudioService["createFFT"]>;
  private waveform?: ReturnType<typeof AudioService["createWaveform"]>;
  private meter?: ReturnType<typeof AudioService["createMeter"]>;

  state = {
    recordingInProgress: false,
    countdown: this.countdownDefault,
    countdownIsActive: false,
    getReady: false,
  };

  public componentDidMount() {
    this.resizeCanvas();
    window.addEventListener("resize", this.resizeCanvas);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.resizeCanvas);
    this.cleanupAnimation();
  }

  public async componentDidUpdate() {
    const { url } = this.context.project.tracks[this.props.index];

    if (url) {
      this.connectNodeToAnimationSources(
        this.props.audioService.getPlayerByIndex(this.props.index)
      );
      this.startAnimation();
    }
  }

  private countdown = () => {
    this.setState({
      countdown: this.state.countdown - 1,
      countdownIsActive: true,
      getReady: false,
    });
  };

  private onRecordClick = async () => {
    this.setState({
      getReady: true,
    });

    this.props.audioContext.onRecordRequest();

    this.props.audioService.play();

    const countdownLoop = AudioService.createLoop(this.countdown, "4n");

    /**
     * The outer scheduleOnce ensures the inner scheduled callback isn't fired immediately.
     *
     * Scheduling a callback immediately ensures the countdown loop start is scheduled as early
     * as possible. This ensures the loop always begins successfully.
     */
    this.props.audioService.scheduleOnce(() => {
      countdownLoop.start("1:0:0");
      this.props.audioService.scheduleOnce(async () => {
        this.setState({
          recordingInProgress: true,
          countdown: this.countdownDefault,
          countdownIsActive: false,
        });

        countdownLoop.dispose();

        this.connectNodeToAnimationSources(this.props.audioService.mic);
        this.startAnimation();

        this.props.audioContext.onRecord();

        trackRecordingStarted();

        const [blob, player] = await this.props.audioService.capture(
          this.props.index
        );

        this.setState({
          recordingInProgress: false,
        });

        this.cleanupAnimation();
        this.connectNodeToAnimationSources(player);
        this.startAnimation();

        this.context.updateTrack(this.props.index, {
          hasRecorded: true,
          bpm: this.context.masterBpm,
          blob,
          url: "",
        });

        this.props.audioContext.onRecordFinish();

        trackRecordingFinished();
      }, 0);
    }, 0);
  };

  private connectNodeToAnimationSources(node: any) {
    this.fft = AudioService.createFFT(32);
    this.waveform = AudioService.createWaveform(32);
    this.meter = AudioService.createMeter();

    node.fan(this.meter, this.waveform, this.fft);
  }

  private cleanupAnimation() {
    if (this.animationLoop) {
      cancelAnimationFrame(this.animationLoop);
    }
  }

  private startAnimation() {
    const { index } = this.props;
    if (index === 0) {
      this.drawCircle();
    } else if (index === 1) {
      this.drawWave();
    } else if (index === 2) {
      this.drawBars();
    } else {
      this.drawSquare();
    }
  }

  private resizeCanvas = () => {
    requestAnimationFrame(() => {
      const recorderDom = document.querySelector(".recorder") as HTMLElement;
      const context = this.canvasElement.current!.getContext("2d");

      context!.canvas.width = recorderDom.offsetWidth;
      context!.canvas.height = recorderDom.offsetHeight;
    });
  };

  private drawWave = () => {
    if (this.canvasElement.current === null) {
      return;
    }

    const canvasElement = this.canvasElement.current;
    const canvasContext = canvasElement.getContext("2d")!;
    const waveform = this.waveform!.getValue(); // Float32Array
    const { width, height } = canvasElement;
    const padding = width / 10;
    const xInterval = (width - padding * 2) / waveform.length;

    canvasContext.clearRect(0, 0, width, height);
    canvasContext.beginPath();
    canvasContext.lineJoin = "round";
    canvasContext.lineWidth = 20;
    canvasContext.strokeStyle = "#FFDF00";
    canvasContext.lineCap = "round";
    canvasContext.moveTo(padding, ((waveform[0] + 1) / 2) * height);

    for (let i = 1, len = waveform.length; i < len; i++) {
      const val = (waveform[i] + 1) / 2;
      const x = (i + 1) * xInterval + padding;
      const y = val * height;
      canvasContext.lineTo(x, y);
    }

    canvasContext.stroke();

    this.animationLoop = requestAnimationFrame(this.drawWave);
  };

  private drawCircle = () => {
    if (this.canvasElement.current === null) {
      return;
    }

    const canvasElement = this.canvasElement.current;
    const canvasContext = canvasElement.getContext("2d")!;
    const { width, height } = canvasElement;
    const gain = AudioService.dbToGain(this.meter!.getValue() as number);
    const multiplier = gain * 15 + 1;

    canvasContext.clearRect(0, 0, width, height);
    canvasContext.strokeStyle = "#00E15F";
    canvasContext.lineWidth = height / 20;

    [1.5, 3, 5].forEach((divisor) => {
      const radius = height / 2 / divisor;
      canvasContext.beginPath();
      canvasContext.arc(
        width / 2,
        height / 2,
        radius * multiplier,
        0,
        Math.PI * 2,
        true
      );
      canvasContext.stroke();
    });

    this.animationLoop = requestAnimationFrame(this.drawCircle);
  };

  private drawBars = () => {
    if (this.canvasElement.current === null) {
      return;
    }

    const canvasElement = this.canvasElement.current;
    const canvasContext = canvasElement.getContext("2d")!;
    const fftValues = this.fft!.getValue();
    const { width, height } = canvasElement;
    const lineWidth = width / 32 - 3;

    canvasContext.clearRect(0, 0, width, height);
    canvasContext.lineCap = "round";
    canvasContext.strokeStyle = "#FFD200";
    canvasContext.lineWidth = lineWidth;

    for (let i = 0, len = fftValues.length; i < len; i++) {
      const x = width * (i / len) + 3;
      const y = (fftValues[i] + 140) * 2;

      canvasContext.beginPath();
      canvasContext.lineTo(x + lineWidth / 2, height);
      canvasContext.lineTo(x + lineWidth / 2, height - y);
      canvasContext.stroke();
    }

    this.animationLoop = requestAnimationFrame(this.drawBars);
  };

  private drawSquare = () => {
    if (this.canvasElement.current === null) {
      return;
    }

    const canvasElement = this.canvasElement.current;
    const canvasContext = canvasElement.getContext("2d")!;
    const { width, height } = canvasElement;
    const gain = AudioService.dbToGain(this.meter!.getValue() as number) / 4;
    const multiplier = gain * 15 + 1;

    canvasContext.strokeStyle = "#004CFF";
    canvasContext.lineWidth = height / 28;
    canvasContext.lineJoin = "round";

    canvasContext.clearRect(0, 0, width, height);
    canvasContext.translate(width / 2, height / 2);
    canvasContext.rotate(15 * (Math.PI / 180));

    [1.8, 2.3, 6.5].forEach((divisor) => {
      const squareSize = (height / divisor) * multiplier;
      const offset = squareSize / 2;
      canvasContext.strokeRect(-offset, -offset, squareSize, squareSize);
    });

    canvasContext.rotate(-15 * (Math.PI / 180));
    canvasContext.translate(-width / 2, -height / 2);

    this.animationLoop = requestAnimationFrame(this.drawSquare);
  };

  public render() {
    const animationTime = (1000 * 60) / this.context.masterBpm;
    const hasRecorded = this.context.project.tracks[this.props.index]
      .hasRecorded;

    return (
      <div className="recorder">
        <canvas className="recorder__canvas" ref={this.canvasElement} />

        {this.state.countdownIsActive && (
          <div className="recorder__beat">{this.state.countdown}</div>
        )}

        {(this.state.countdownIsActive || this.state.recordingInProgress) && (
          <div
            className="recorder__pulse"
            style={{
              animationDuration: `${animationTime}ms`,
            }}
          />
        )}

        {!this.props.audioContext.recordingRequested && !hasRecorded && (
          <button className="recorder__record" onClick={this.onRecordClick}>
            <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1">
              <circle id="Oval" fill="#000" cx="35" cy="35" r="35" />
              <circle id="Oval" fill="#FF3B3D" cx="35" cy="35" r="28" />
            </svg>
          </button>
        )}

        {this.state.getReady && <GetReady />}

        {!this.props.audioContext.recordingRequested && (
          <button
            className={cx("recorder__delete", {
              "recorder__delete--inactive": !hasRecorded,
            })}
            onClick={() => {
              if (hasRecorded) {
                this.props.audioService.disposePlayerByIndex(this.props.index);
                this.context.deleteTrack(this.props.index);
              }
            }}
          >
            <span className="visuallyhidden">Delete recording</span>
            <TrashIcon />
          </button>
        )}
      </div>
    );
  }
}

export default Window;
