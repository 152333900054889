import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./App.css";

import SharingDialog from "./components/sharing";
import Toolbar from "./components/toolbar";

import ProjectProvider, { ProjectTemplate } from "./providers/project";

import {
  trackSharingDialogOpened,
  trackSharingDialogClosed,
} from "./helpers/analytics";

import { AudioService } from "./services/audio";
import AudioProvider, { AudioContext } from "./providers/audio";
import Grid from "./components/grid";

interface Props {
  audioService: AudioService;
}

const App: React.FC<Props> = ({ audioService }) => {
  const [shouldShowSharingDialog, setShouldShowSharingDialog] = useState(false);
  const [homeUrl, setHomeUrl] = useState("https://make.techwillsaveus.com");
  const [template, setTemplate] = useState<ProjectTemplate | undefined>(
    undefined
  );
  const hideSharingDialog = () => {
    trackSharingDialogClosed();
    setShouldShowSharingDialog(false);
  };
  useEffect(() => {
    const { location } = window;
    const homeUrlParam = new URL(location.href).searchParams.get("back");
    const templateParam = new URL(location.href).searchParams.get("template");

    if (homeUrlParam) {
      setHomeUrl(homeUrlParam);
    }

    if (templateParam) {
      setTemplate(templateParam as ProjectTemplate);
    }

    window.history.pushState(null, "", location.pathname);
  }, []);
  return (
    <ProjectProvider template={template} audioService={audioService}>
      <AudioProvider audioService={audioService}>
        <Toolbar
          homeUrl={homeUrl}
          sharingVisible={shouldShowSharingDialog}
          onSharingPress={() => {
            trackSharingDialogOpened();
            setShouldShowSharingDialog(true);
          }}
          onSharingClose={hideSharingDialog}
        />
        <div className="body">
          <AudioContext.Consumer>
            {(audioContext) => (
              <Grid audioContext={audioContext} audioService={audioService} />
            )}
          </AudioContext.Consumer>
          <CSSTransition
            in={shouldShowSharingDialog}
            unmountOnExit
            mountOnEnter
            timeout={200}
            classNames="sharing-dialog"
          >
            <SharingDialog
              onCloseClick={hideSharingDialog}
              url={`${window.location.origin}/share${window.location.pathname}`}
            />
          </CSSTransition>
        </div>
      </AudioProvider>
    </ProjectProvider>
  );
};

export default App;
