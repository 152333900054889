import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
  onClick: () => void;
  textToCopy: string;
  copied: boolean;
}

const CopyButton: React.FC<Props> = ({ onClick, textToCopy, copied }) => (
  <CopyToClipboard onCopy={onClick} text={textToCopy}>
    <button className="sharing-dialog__button">
      <span className="sharing-dialog__button-text">
        {copied ? 'Copied' : 'Copy'}
      </span>
    </button>
  </CopyToClipboard>
);

export default CopyButton;