import React from "react";

const MetronomeIcon = () => (
  <>
    <svg className="icon mq-min-800" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 30C6.716 30 0 23.284 0 15 0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm4.865-14.02c-.135-.335-.277-.667-.44-1.055l-.094.24c-.441 1.17-.88 2.34-1.322 3.51-.101.268-.287.408-.518.402-.242-.006-.418-.162-.5-.45l-.678-2.42-.665-2.375c-.033.1-.052.152-.066.205-.17.639-.335 1.279-.507 1.916-.067.25-.227.4-.461.403-.747.007-1.495.008-2.242-.001-.268-.003-.46-.225-.465-.49-.005-.305.21-.521.546-.526a49.22 49.22 0 0 1 1.537.002c.15.003.204-.05.24-.192.288-1.115.584-2.227.878-3.34.085-.321.253-.47.52-.47.257.002.429.153.514.455l.683 2.443.745 2.658c.044-.097.075-.158.099-.223.403-1.07.808-2.141 1.206-3.212.09-.246.22-.431.508-.441.253-.01.402.106.529.409.242.577.485 1.153.719 1.732.053.13.117.185.265.18.462-.01.925-.01 1.387.002.158.004.242-.057.32-.186.572-.926.838-1.927.717-3.012-.196-1.764-1.086-3.07-2.722-3.761-1.66-.7-3.233-.424-4.644.696-.277.22-.518.486-.781.736-.166-.165-.331-.331-.5-.495-1.772-1.731-4.588-1.732-6.327-.001-1.806 1.797-1.795 4.755.036 6.632 2.082 2.134 4.173 4.26 6.26 6.389.388.394.684.393 1.07-.003 1.46-1.488 2.922-2.976 4.379-4.47a.463.463 0 0 1 .37-.154c1.216.007 2.433.002 3.65.005.307 0 .542-.118.676-.396.224-.462-.115-.97-.652-.971a459.77 459.77 0 0 0-3.714.002c-.283 0-.454-.12-.556-.373z" fillRule="nonzero" fill="#FAD205"/>
    </svg>
    <svg className="icon mq-max-800" width="23" height="19" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.57 10.36c.132.329.352.486.716.484 1.594-.009 3.19-.006 4.784-.002.692.002 1.128.66.84 1.26-.173.361-.475.516-.87.515-1.568-.004-3.136.002-4.702-.007a.594.594 0 0 0-.477.201c-1.876 1.939-3.758 3.87-5.639 5.802-.498.515-.88.516-1.378.004-2.69-2.764-5.382-5.524-8.064-8.294-2.358-2.437-2.373-6.278-.046-8.61 2.24-2.248 5.866-2.247 8.15 0 .217.213.429.429.643.643.339-.324.649-.67 1.006-.955C13.35-.053 15.377-.412 17.514.497c2.107.897 3.254 2.593 3.506 4.883.156 1.409-.186 2.708-.922 3.91-.102.168-.21.247-.413.242a37.251 37.251 0 0 0-1.787-.001c-.19.005-.273-.067-.341-.235-.302-.752-.615-1.5-.926-2.25-.164-.392-.356-.543-.682-.53-.37.013-.537.253-.654.572-.513 1.391-1.034 2.781-1.553 4.17-.031.085-.071.164-.128.29l-.96-3.45c-.293-1.059-.585-2.114-.88-3.172-.109-.393-.33-.589-.661-.59-.344-.001-.56.192-.67.61-.379 1.444-.76 2.888-1.13 4.335-.047.186-.115.253-.31.25-.66-.013-1.32-.01-1.98-.003-.432.006-.71.287-.703.683.008.344.254.632.6.636.961.012 1.925.01 2.887.001.301-.002.507-.197.594-.523.221-.827.435-1.658.653-2.487.018-.07.042-.137.085-.267.296 1.069.575 2.078.856 3.084.291 1.049.582 2.095.875 3.143.104.373.33.575.642.583.298.008.538-.174.668-.52.569-1.52 1.134-3.04 1.702-4.559.031-.082.065-.165.123-.312.208.504.391.935.565 1.37z" fill="#FAD205" fillRule="evenodd"/>
    </svg>
  </>
);

export default MetronomeIcon;