import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import {
  Player,
  UserMedia,
  Unit,
  Transport,
  FFT,
  Waveform,
  Meter,
  start,
  Destination,
  Loop,
  dbToGain,
  context as ToneContext,
  Volume,
} from "tone";

import { initialMasterBpm } from "../config";
import { isAndroid } from "../helpers/device";

export class AudioService {
  private metronome!: Player;
  private players!: Array<Player>;
  private recorder: any;
  private audioDestination!: MediaStreamAudioDestinationNode;
  private reducedSupportStream?: MediaStream;

  private static volumes = {
    low: -18,
    default: 0,
  };

  public mic!: UserMedia;

  public static dbToGain(db: Unit.Decibels) {
    return dbToGain(db);
  }

  public static createLoop(countdownCallback: any, interval: Unit.Time) {
    return new Loop(countdownCallback, interval);
  }

  public static createFFT(size: Unit.PowerOfTwo) {
    return new FFT(size);
  }

  public static createWaveform(size: Unit.PowerOfTwo) {
    return new Waveform(size);
  }

  public static createMeter() {
    return new Meter();
  }

  constructor() {
    // this allows us to have an async method run during the class instantiation
    this.initialize();
  }

  private async initialize() {
    this.metronome = new Player("/woodblock.wav").toDestination();
    Transport.scheduleRepeat((time: number) => {
      this.metronome.start(time);
    }, "4n");

    this.players = [1, 2, 3, 4].map(
      () =>
        new Player({
          loop: true,
        })
    );

    this.audioDestination = ToneContext.createMediaStreamDestination();
    if (isAndroid) {
      this.reducedSupportStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
    }

    this.mic = new UserMedia();
    this.mic.open();
    // no audio is recorded from this mic, it only powers the animations
    this.mic.connect(this.audioDestination);

    // ideally RecordRTC would be replaced by https://tonejs.github.io/docs/14.7.58/Recorder to simplify the application's dependencies
    this.recorder = RecordRTC(
      this.reducedSupportStream ?? this.audioDestination.stream,
      {
        type: "audio",
        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,
      }
    );

    Transport.bpm.value = initialMasterBpm;
    Transport.loop = true;
    Transport.loopStart = "0";
    Transport.loopEnd = "2m";
  }

  public capture = (playerIndex: number): Promise<[Blob, Player]> => {
    this.setVolume(AudioService.volumes.low);

    this.recorder.startRecording();

    return new Promise((resolve) => {
      this.scheduleOnce(() => {
        this.recorder.stopRecording(async () => {
          this.setVolume(AudioService.volumes.default);

          const blob = this.recorder.getBlob();
          const blobUrl = URL.createObjectURL(blob);
          const player = await this.setPlayerAudio(playerIndex, blobUrl);

          this.recorder.reset();

          return resolve([blob, player]);
        });
      }, 0);
    });
  };

  public scheduleOnce(callback: any, time: Unit.Time) {
    Transport.scheduleOnce(callback, time);
  }

  public isPlaying = () => {
    return Transport.state === "started";
  };

  public isMetronomePlaying = () => {
    return !this.metronome.mute;
  };

  public togglePlay = async () => {
    if (this.isPlaying()) {
      this.stop();
    } else {
      this.play();
    }
  };

  public async play() {
    await start();
    Transport.start();
  }

  public stop() {
    Transport.pause();
  }

  public setVolume(volume: number) {
    const volumeNode = new Volume(volume);
    Destination.chain(volumeNode);
  }

  public setBpm(bpm: number) {
    Transport.bpm.value = bpm;
  }

  public toggleMetronome = () => {
    if (this.isMetronomePlaying()) {
      this.metronome.mute = true;
    } else {
      this.metronome.mute = false;
    }
  };

  public getPlayerByIndex = (index: number) => {
    return this.players[index];
  };

  public disposePlayerByIndex = (index: number) => {
    this.getPlayerByIndex(index).dispose();
  };

  public isPlayerLoaded = (index: number) => {
    return this.getPlayerByIndex(index).loaded;
  };

  public setPlayerPlaybackRate = (playbackRate: number, index: number) => {
    const player = this.getPlayerByIndex(index);
    if (player.playbackRate !== playbackRate) {
      player.playbackRate = playbackRate;
    }
  };

  public setPlayerAudio = async (index: number, url: string) => {
    const player = await this.getPlayerByIndex(index).load(url);

    player.toDestination().sync();

    const isBlobUrl = url.indexOf("blob") > -1;
    if (isBlobUrl) {
      // auto play audio recorded in this session
      player.start(0);
    } else {
      // start player when Transport starts if loading a saved project
      Transport.on("start", () => {
        if (player.state === "stopped") {
          player.start(0);
        }
      });
    }

    return player;
  };
}
