import React from "react";

const PlayIcon = () => (
  <>
    <svg className="icon mq-min-800" width="36" height="36" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 36C8.059 36 0 27.941 0 18S8.059 0 18 0s18 8.059 18 18-8.059 18-18 18zm2.555-13.11l5.326-3.368a1.812 1.812 0 0 0 0-3.063L16.11 10.28a1.81 1.81 0 0 0-2.781 1.531v12.358a1.812 1.812 0 0 0 2.781 1.532l2.28-1.442c.163.632.551 1.048.796 1.258.118.101.3.098.415-.007.794-.727.994-1.71.954-2.62zm-7.227-6.562c-4.865-.14-4.309 3.058-4.309 3.058 2.78.555 4.31-3.058 4.31-3.058zm0 0s.022-3.778-3.919-4.403c0 0-1.084 3.846 3.92 4.403zm9.799 0a.832.832 0 1 1-1.665 0 .832.832 0 0 1 1.665 0z" fill="#FAD205" fillRule="nonzero"/>
    </svg>
    <svg className="icon mq-max-800" width="19" height="23" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.924 13.09L2.765 21.91A1.84 1.84 0 0 1 0 20.319V2.68a1.84 1.84 0 0 1 2.765-1.59L17.924 9.91a1.84 1.84 0 0 1 0 3.18z" fill="#FAD205" fillRule="evenodd"/>
    </svg>
  </>
);

export default PlayIcon;