import React from 'react';
import './styles.css';

const GetReady: React.FC = () => (
  <div className="get-ready">
    Get ready!
  </div>
);

export default GetReady;
