import React from "react";

const ShareIcon = () => (
  <svg className="icon" width="28" height="22" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#FFD300" strokeWidth="1.3" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M16.997 12.853l-.894.72.961-2.1 2.653 3.575L27.01 1.5 9.87 8.484l5.867 1.583 6.695-4.522M3.415 15.048l6.456-4.222M11.139 20.309l4.765-3.115M5.642 20.728l6.456-4.221M13.513 15.581l1.31-.855M7.956 15.68l5.142-3.362M1.5 19.87l5.142-3.363"/>
    </g>
  </svg>
);

export default ShareIcon;