import React from "react";
import { Transport } from "tone";
import cx from "classnames";

interface Props {
  recording: boolean;
}

interface State {
  position: string;
}
class Timeline extends React.PureComponent<Props, State> {
  state = {
    position: "",
  };

  componentDidMount() {
    this.start();
  }

  start = () => {
    this.setState({
      position: `${(
        (Transport.seconds / (Transport.loopEnd as number)) *
        100
      ).toFixed(2)}%`,
    });

    window.requestAnimationFrame(this.start);
  };

  render() {
    const { recording } = this.props;
    const { position } = this.state;

    return (
      <div
        className={cx("timeline", {
          "timeline--recording": recording,
        })}
      >
        <div className="timeline__track" />
        <div style={{ width: `${position}` }} className="timeline__track-alt" />
      </div>
    );
  }
}

export default Timeline;
