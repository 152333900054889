import React from "react";

const StopIcon = () => (
  <>
    <svg className="icon mq-min-800" width="36" height="36" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 36C8.059 36 0 27.941 0 18S8.059 0 18 0s18 8.059 18 18-8.059 18-18 18zm-6.033-17.715v4.847c0 1.19.966 2.157 2.157 2.157h3.773a3.08 3.08 0 0 0-.063.8c.058 1.008.635 1.647.974 1.938a.61.61 0 0 0 .401.147c.15 0 .3-.053.415-.16.842-.77 1.081-1.782 1.062-2.725h3.702a2.157 2.157 0 0 0 2.156-2.157V12.868a2.156 2.156 0 0 0-2.156-2.156H14.124a2.157 2.157 0 0 0-2.157 2.156v1.376c-.586-.933-1.607-1.827-3.382-2.108a.305.305 0 0 0-.338.216c-.018.063-.436 1.598.473 2.947.409.607 1.013 1.071 1.807 1.39-.888.19-1.569.555-2.036 1.095-.837.966-.648 2.114-.64 2.162.021.124.117.22.238.245.226.045.441.066.65.066 1.483 0 2.566-1.054 3.228-1.972zm11.217-1.984a.832.832 0 1 1-.001-1.665.832.832 0 0 1 .001 1.665z" fill="#FAD205" fillRule="nonzero"/>
    </svg>
    <svg className="icon mq-max-800" width="19" height="23" viewBox="0 0 19 23">
      <rect fill="#FAD205" x="0" y="2" width="19" height="19" rx="2" />
    </svg>
  </>
);

export default StopIcon;