import React, { useEffect, useState, useContext } from "react";

import { AudioService } from "../services/audio";
import { ProjectContext } from "./project";

const defaultState = {
  playing: false,
  metronome: true,
  recordingRequested: false,
  recording: false,
};

export interface AudioContextType {
  playing: boolean;
  togglePlaying: () => void;
  metronome: boolean;
  toggleMetronome: () => void;
  setBpm: (bpm: number) => void;
  recording: boolean;
  recordingRequested: boolean;
  onRecordRequest: () => void;
  onRecord: () => void;
  onRecordFinish: () => void;
}

export const AudioContext = React.createContext<AudioContextType>({
  ...defaultState,
  togglePlaying: () => {},
  toggleMetronome: () => {},
  setBpm: () => {},
  onRecordRequest: () => {},
  onRecord: () => {},
  onRecordFinish: () => {},
});

interface Props {
  audioService: AudioService;
}

const AudioProvider: React.FC<Props> = ({ children, audioService }) => {
  const { project, updateBpm } = useContext(ProjectContext);
  const [playing, setPlaying] = useState(audioService.isPlaying());
  const [metronome, setMetronome] = useState(audioService.isMetronomePlaying());
  const [recordingRequested, setRecordingRequested] = useState(false);
  const [recording, setRecording] = useState(false);
  useEffect(() => {
    if (project.isShare) {
      audioService.toggleMetronome();
      setMetronome((metronome) => !metronome);
    }
  }, [project.isShare, audioService]);
  return (
    <AudioContext.Provider
      value={{
        playing,
        togglePlaying: () => {
          audioService.togglePlay();
          setPlaying(!playing);
        },
        metronome,
        toggleMetronome: () => {
          audioService.toggleMetronome();
          setMetronome(!metronome);
        },
        setBpm: (bpm) => {
          audioService.setBpm(bpm);
          updateBpm(bpm);
        },
        recordingRequested,
        onRecordRequest: () => {
          setRecordingRequested(true);
          setPlaying(true);
        },
        recording,
        onRecord: () => {
          setRecording(true);
        },
        onRecordFinish: () => {
          setRecording(false);
          setRecordingRequested(false);
        },
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export default AudioProvider;
