import React, { useContext } from "react";

import { ProjectContext } from "../../providers/project";
import { AudioContextType } from "../../providers/audio";
import { AudioService } from "../../services/audio";

import Window from "../window";

interface Props {
  audioService: AudioService;
  audioContext: AudioContextType;
}

const Grid: React.FC<Props> = ({ audioService, audioContext }) => {
  const { project } = useContext(ProjectContext);
  return (
    <div className="grid">
      {[0, 1, 2, 3].map((index: number) => (
        <Window
          key={`track-${project.tracks[index].id}`}
          audioService={audioService}
          audioContext={audioContext}
          index={index}
        />
      ))}
    </div>
  );
};

export default Grid;
