import { timeout } from "./timeout";

import { apiDomain } from '../config';

export const callApi: 
  <T>(input: RequestInfo, init?: RequestInit, timeoutDuration?: number) => Promise<T> =
    (input, init = {}, timeoutDuration = 10000) =>
      Promise.race([
        fetch(`${apiDomain}/api${input}`, Object.assign({}, init, {
          credentials: 'include'
        }))
        .then(res => {
          if(res.ok) {
            return res;
          } else {
            throw new Error(`${res.status}`);
          }
        })
        .then(res => res.json())
        .then(({ data }) => data),
        timeout(timeoutDuration)
      ]);
