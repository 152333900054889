import React, { Component } from "react";
import { debounce } from "debounce";

import getRandomName from "../helpers/name-generator";
import { callApi } from "../helpers/api";
import { initialMasterBpm, apiDomain } from "../config";
import {
  trackProjectBpmChanged,
  trackRecordingDeleted,
  trackProjectSaved,
  trackProjectSaveError,
  trackProjectLoadError,
  trackProjectRenamed,
} from "../helpers/analytics";
import { AudioService } from "../services/audio";

export interface ApiTrack {
  bpm: number;
  url: string;
}

export interface ApiProject {
  _id?: string;
  _userId?: string;
  name: string;
  makername: string;
  permalink: string;
  tracks: ApiTrack[];
  masterBpm: number;
}

export interface Track {
  id: number;
  bpm: number;
  playbackRate: number;
  hasRecorded: boolean;
  blob?: Blob;
  url?: string;
}

export interface Project
  extends Omit<ApiProject, "tracks" | "permalink" | "makername"> {
  tracks: Track[];
  makername?: string;
  isDirty: boolean;
  isSaving: boolean;
  isShare: boolean;
}

type State = Project;

export type ProjectTemplate = "electro-guitar" | "synth-wave" | "record-remix";

interface Props {
  template?: ProjectTemplate;
  audioService: AudioService;
}

const createTrack = (id: number, bpm: number): Track => ({
  id,
  bpm,
  hasRecorded: false,
  playbackRate: 1,
});

const defaultState = {
  _id: undefined,
  _userId: undefined,
  name: getRandomName(),
  tracks: [
    createTrack(1, initialMasterBpm),
    createTrack(2, initialMasterBpm),
    createTrack(3, initialMasterBpm),
    createTrack(4, initialMasterBpm),
  ],
  masterBpm: initialMasterBpm,
  isDirty: true,
  isSaving: false,
  isShare: false,
};

export const ProjectContext = React.createContext({
  project: defaultState,
  saveProject: () => {},
  updateProjectName: (_: string) => {},
  deleteTrack: (_: number) => {},
  updateTrack: (_: number, __: { [key: string]: any }) => {},
  updateBpm: (_: number) => {},
  flagDirty: () => {},
  masterBpm: initialMasterBpm,
});

let nextTrackId = 5;
let templateApplied = false;

const getTemplateBpm = (template: ProjectTemplate) => {
  let bpm;
  switch (template) {
    case "electro-guitar":
      bpm = 160;
      break;
    case "synth-wave":
      bpm = 139;
      break;
    case "record-remix":
      bpm = 127;
      break;
    default:
      bpm = 120;
  }
  return bpm;
};

class ProjectService extends Component<Props, State> {
  public state = defaultState;

  async componentDidMount() {
    this.retrieveProject();
  }

  componentDidUpdate() {
    if (this.props.template && !templateApplied) {
      templateApplied = true;
      this.applyTemplate(this.props.template);
    }
  }

  private updatePath({ _id, makername, permalink }: ApiProject) {
    window.history.pushState(null, "", `/${makername}/${permalink}/${_id}`);
  }

  private async retrieveProject() {
    const { pathname } = window.location;
    const shouldRetrieveProject = pathname !== "/";
    const pathParts = pathname.split("/");
    const isShare = pathParts[1] === "share";
    const id = pathParts[pathParts.length - 1];

    if (shouldRetrieveProject) {
      this.setState({ isShare });
      callApi<ApiProject>(`/loops/${id}`, {
        method: "GET",
      })
        .then(this.syncClientWithApiProject)
        .catch(() => {
          trackProjectLoadError();
          alert(
            "Something went wrong loading the project. Please try again later."
          );
        });
    }
  }

  private loadTracks = () => {
    this.state.tracks.forEach((track, i) => {
      if (track.url) {
        this.props.audioService.setPlayerAudio(i, track.url);
      }
    });
  };

  public applyTemplate = (template: ProjectTemplate) => {
    const templateBpm = getTemplateBpm(template);
    this.setState(
      {
        masterBpm: templateBpm,
        tracks: this.state.tracks.map((track: Track, i: number) => {
          if (i === 3) {
            return {
              ...track,
              url: `${
                apiDomain === "https://localhost"
                  ? "https://twsu-development.s3.amazonaws.com"
                  : "https://make-cdn.techwillsaveus.com"
              }/loops/samples/${template}-record-a-song.wav`,
              hasRecorded: true,
              bpm: templateBpm,
            };
          }
          return track;
        }),
      },
      this.loadTracks
    );
    this.props.audioService.setBpm(templateBpm);
  };

  public syncClientWithApiProject = (project: ApiProject) => {
    const {
      _id,
      _userId,
      makername,
      name,
      tracks: apiTracks,
      masterBpm,
    } = project;
    const { tracks } = this.state;

    this.props.audioService.setBpm(masterBpm);

    this.setState(
      {
        _id,
        _userId,
        makername,
        name,
        masterBpm,
        tracks: tracks.map((track: Track, i: number) => ({
          ...track,
          ...apiTracks[i],
          hasRecorded: Boolean(apiTracks[i] && apiTracks[i].url),
        })),
        isDirty: false,
      },
      () => {
        this.loadTracks();
        this.updateTrackPlaybackRates();
      }
    );

    return project;
  };

  public saveProject = () => {
    const { _id, name, tracks, masterBpm } = this.state;
    const body = new FormData();

    body.append("name", name);
    body.append("masterBpm", masterBpm.toString());
    tracks.forEach((track: Track) => {
      body.append("trackBpms", track.bpm.toString());
      if (track.blob && !track.url) {
        body.append("blobs", track.blob);
        body.append("upload", "true");
      } else {
        body.append("urls", track.url || "");
        body.append("upload", "false");
      }
    });

    trackProjectSaved();

    this.setState({
      isSaving: true,
    });

    (_id ? this.updateProject : this.createProject)(body)
      .then(this.syncClientWithApiProject)
      .then(this.updatePath)
      .then(this.flagClean)
      .catch(({ message: statusCode }: Error) => {
        trackProjectSaveError();

        if (statusCode === "403") {
          alert("This project doesn't belong to you.");
        } else if (statusCode === "401") {
          alert("You need to login to Club Make to save your project.");
        } else {
          alert("Something went wrong. Please try again later.");
        }
      })
      .then(() =>
        this.setState({
          isSaving: false,
        })
      );
  };

  public createProject(body: FormData) {
    return callApi<ApiProject>("/loops", {
      method: "POST",
      body,
    });
  }

  public updateProject = (body: FormData) => {
    const { _id } = this.state;

    return callApi<ApiProject>(`/loops/${_id}`, {
      method: "PUT",
      body,
    });
  };

  private flagClean = () => {
    this.setState({
      isDirty: false,
    });
  };

  private flagDirty = () => {
    this.setState({
      isDirty: true,
    });
  };

  public trackProjectRenamed = debounce(trackProjectRenamed, 1000);

  private updateProjectName = (name: string) => {
    this.trackProjectRenamed();

    this.setState({
      name,
      isDirty: true,
    });
  };

  private deleteTrack = (index: number) => {
    trackRecordingDeleted();

    const tracksClone = [...this.state.tracks] as Track[];
    tracksClone.splice(
      index,
      1,
      createTrack(nextTrackId, this.state.masterBpm)
    );
    this.setState({
      tracks: tracksClone,
      isDirty: true,
    });
    nextTrackId++;
  };

  private updateTrackPlaybackRates = debounce(() => {
    const tracks = this.state.tracks.map(
      (track: Track): Track => {
        if (track.hasRecorded) {
          return {
            ...track,
            playbackRate: Number((this.state.masterBpm / track.bpm).toFixed(2)),
          };
        }
        return track;
      }
    );

    this.setState(
      {
        tracks,
      },
      () => {
        this.state.tracks.forEach(({ playbackRate }, index) => {
          this.props.audioService.setPlayerPlaybackRate(playbackRate, index);
        });
      }
    );
  }, 50);

  private updateTrack = (index: number, update: { [key: string]: any }) => {
    const tracks = [...this.state.tracks] as Track[];

    tracks[index] = {
      ...tracks[index],
      ...update,
    };

    this.setState(
      {
        tracks,
        isDirty: true,
      },
      () => {
        const { playbackRate } = this.state.tracks[index];
        this.props.audioService.setPlayerPlaybackRate(playbackRate, index);
      }
    );
  };

  public trackProjectBpmChanged = debounce(trackProjectBpmChanged, 1000);

  private updateBpm = (bpm: number) => {
    this.trackProjectBpmChanged();

    this.setState(
      {
        masterBpm: bpm,
        isDirty: true,
      },
      this.updateTrackPlaybackRates
    );
  };

  render() {
    const { masterBpm } = this.state;
    return (
      <ProjectContext.Provider
        value={{
          project: this.state,
          saveProject: this.saveProject,
          updateProjectName: this.updateProjectName,
          deleteTrack: this.deleteTrack,
          updateTrack: this.updateTrack,
          updateBpm: this.updateBpm,
          flagDirty: this.flagDirty,
          masterBpm,
        }}
      >
        {this.props.children}
      </ProjectContext.Provider>
    );
  }
}

export default ProjectService;
