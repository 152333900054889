import React, { useState } from 'react';
import './styles.scss';
import closeIcon from "./icons/close.svg";

import CopyButton from "./CopyButton";
import { trackShareLinkCopied } from '../../helpers/analytics';

interface Props {
  url: string;
  onCloseClick: () => void;
}

const SharingDialog: React.FC<Props> = ({ url, onCloseClick }) => {
  const [copied, setCopied] = useState(false);
  const onCopyClick = () => {
    trackShareLinkCopied();
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  return (
    <div className="sharing-dialog">
      <div className="sharing-dialog__content">
        <button onClick={onCloseClick} className="sharing-dialog__close">
          <img alt="" src={closeIcon} />
          <span className="visuallyhidden">Click to close share dialog</span>
        </button>
        <section className="sharing-dialog__step sharing-dialog__step--first">
          <header className="sharing-dialog__heading-container">
            <h1 className="sharing-dialog__heading">1. Copy this link</h1>
            <span className="mq-max-600">
              <CopyButton onClick={onCopyClick} textToCopy={url} copied={copied} />
            </span>
          </header>
          <div className="sharing-dialog__input-container">
            <input className="sharing-dialog__input" value={url} readOnly />
            <span className="mq-min-600">
              <CopyButton onClick={onCopyClick} textToCopy={url} copied={copied} />
            </span>
          </div>
        </section>
        <section className="sharing-dialog__step">
          <header className="sharing-dialog__heading-container">
            <h1 className="sharing-dialog__heading">2. Paste it anywhere and tell your friends &amp; family!</h1>
          </header>
          <img className="sharing-dialog__image" src="/images/sharing.jpg" alt="" />
          <img className="sharing-dialog__image sharing-dialog__image--wide" src="/images/sharing-wide.jpg" alt="" />
        </section>
      </div>
    </div>
  );
};

export default SharingDialog;
