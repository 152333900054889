import React from "react";
import Share from "./Share";

interface Props {
  toggled: boolean;
}

const ShareToggle: React.FC<Props> = ({ toggled }) => (
  toggled ? (
    <svg style={{ paddingTop: 4, paddingLeft: 4 }} className="icon" width="28" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6.762L16.091.67a2.29 2.29 0 0 1 3.238 3.238L13.24 10l6.09 6.091a2.29 2.29 0 1 1-3.238 3.238L10 13.24l-6.091 6.09a2.29 2.29 0 0 1-3.238-3.238L6.76 10 .672 3.909A2.29 2.29 0 1 1 3.909.67L10 6.76z" fill="#FAD205" fillRule="nonzero"/>
    </svg>
  ) : <Share />
);

export default ShareToggle;