import React from 'react';
import cx from "classnames";

const JaggedEdge: React.FC<{ invert?: boolean }> = ({ invert }) => (
  <div className={cx("jagged", {
    "jagged--invert": invert
  })}>
    <svg height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 50">
      <path d="M7 0v50H1.48l4.71-26.45a1.11 1.11 0 0 0 0-.48 1 1 0 0 0-1.26-.65L1.3 23.56a1 1 0 0 1-.49 0A1 1 0 0 1 0 22.42L4.34 0z" fill={invert ? "black" : "#414141"} fillRule="evenodd"/>
    </svg>
  </div>
);

export default JaggedEdge;